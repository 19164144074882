import React, { useEffect, useState } from 'react';
import content from '../data/data.json';
import '../styles/Contact.css';

function Contact() {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(content.contact);
  }, []);

  return (
    <div className="contact">
      <section className="contact-hero">
        {/* You can add an image or background here */}
      </section>
      <div className="container">
        <section className="contact-header">
          <h3>Better yet, see us in person!</h3>
          <h4>We love our customers, so feel free to visit during normal business hours.</h4>
        </section>
        <section className="contact-info">
          <div className="contact-details">
            <h2>{data.company}</h2>
            <p><strong>Phone:</strong> {data.phone}</p>
            <p><strong>Email:</strong> {data.email}</p>
            <p><strong>Hours:</strong> {data.hours}</p>
          </div>
          <div className="contact-form">
            <h2>Drop us a line!</h2>
            <form>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone*</label>
                <input type="tel" id="phone" name="phone" required />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company*</label>
                <input type="text" id="company" name="company" required />
              </div>
              <div className="form-group">
                <label htmlFor="position">Position*</label>
                <input type="text" id="position" name="position" required />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject*</label>
                <input type="text" id="subject" name="subject" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message"></textarea>
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;
