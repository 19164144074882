
import React from 'react';
import './css/Glossary.css';

const glossaryData = [
  { term: 'Part', description: 'Part is building block of a BOM, also designates an Inventory Item. Also called Company P/N or Item Number. ' },
  { term: 'Component', description: 'Component is the manufacturer P/N we are purchasing from manufacturers. The Combination of Manufacturer and its P/N is a Component. ' },
  { term: 'P/N', description: 'Part Number ' },
  { term: 'AVL', description: 'Approved Vendor List. (Also called – PPL, APL, QPL item) ' },
  { term: 'APL', description: 'Approved Parts List' },
  { term: 'PPL', description: 'Preferred Parts List. ' },
  { term: 'QPL', description: 'Qualified Parts List ' },
  { term: '*', description: 'All this terms defines the Catalog or the list of Parts (Company P/N’s) and their Components that are cataloged in the Company. ' },
  { term: 'Manufacturer', description: 'company that Designs and Manufacturing Products.' },
  { term: 'Contractor', description: 'A Company or an Individual that is hired by Contract to perform specific activity for a company.​' },
  { term: 'CM', description: 'Contract Manufacturer or Contractor. A Company that is manufacturing a product by contract.' },
  { term: 'Vendor', description: 'A general definition for: Supplier, Distributor, Franchised Distributor, Rep. (Representative)., Manufacturer' },
  { term: 'PO', description: 'Purchase Order.' },
  { term: 'PCN', description: 'Product Change Notice.' },
  { term: 'Display real testimonials', description: 'Are your customers raving about you on social media? Share their great stories to help turn potential customers into loyal ones.' },
  { term: 'PDN', description: 'Product Discontinue Notice.' },
  { term: 'EOL', description: 'End of Life.' },
  { term: 'PLM', description: 'Product life-cycle management.' },
  { term: 'PDM', description: 'Product Data Management.' },
  { term: 'ERP', description: 'Enterprise resource planning.' },
  { term: 'MRP', description: 'Material Requirements Planning.' },
  { term: 'NPI', description: 'New Product Introduction.' },
];

function Glossary() {
  return (
    <div className="glossary-container">
      <table className="glossary-table">
        <thead>
          <tr>
            <th>Term</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {glossaryData.map((item, index) => (
            <tr key={index}>
              <td className="term">{item.term}</td>
              <td className="description">{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Glossary;
